.pageSlider-enter.left > .page {
  transform: translate3d(200%, 0, 0);
}

.pageSlider-enter.right > .page {
  transform: translate3d(-200%, 0, 0);
}

.pageSlider-exit.left > .page {
  transform: translate3d(-200%, 0, 0);
}

.pageSlider-exit.right > .page {
  transform: translate3d(200%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active > .page {
  transform: translate3d(0, 0, 0);
  transition: all 800ms ease-in-out;
}
